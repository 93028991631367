import { render, staticRenderFns } from "./VideoChatModeMainMobile.vue?vue&type=template&id=31c40629&scoped=true&"
import script from "./VideoChatModeMain.ts?vue&type=script&lang=ts&"
export * from "./VideoChatModeMain.ts?vue&type=script&lang=ts&"
import style0 from "./VideoChatModeMainMobile.vue?vue&type=style&index=0&lang=css&"
import style1 from "./VideoChatModeMainMobile.vue?vue&type=style&index=1&id=31c40629&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31c40629",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VAppBar,VBadge,VBtn,VCard,VContainer,VDialog,VFadeTransition,VIcon,VMain,VProgressCircular,VRow,VSnackbar,VSpacer})
