import { Vue, Component, Provide, Watch, Prop } from "vue-property-decorator";
import { ProfileService } from "@/models/profile/ProfileService";
import { RoomInfoService } from "@/models/room/RoomInfoService";
import {
    DragableBoxView,
    getUrlParameter,
    DeviceStreamManager,
    DeviceMediaStreamWrapper,
    DeviceInfo,
    InputDialog,
    ConfirmDialog,
    ConfirmDialogContent,
    BrowserUtility,
    BrowserType
} from "ui-gallery";
import { VideoChatUseCase } from "@/models/webRtc/VideoChatUseCase";
import { ScreenShareUseCase } from "@/models/webRtc/ScreenShareUseCase";
import { WebRtcService } from "@/models/webRtc/WebRtcService";
import ConnectionSettingDialog from "@/components/Connections/organisms/ConnectionSettingDialog.vue";
import { ConnectionSetting, QualityType } from "@/models/connection/ConnectionSetting";
import FooterCommandBox from "@/components/Connections/molecles/FooterCommandBox.vue";
import TimelineView from "@/components/Connections/templates/TimelineView.vue";
import ChatView from "@/components/Connections/templates/ChatView.vue";
import VideoChat from "@/components/Connections/templates/VideoChat.vue";
import ScreenShare from "@/components/Connections/templates/ScreenShare.vue";
import DocumentShare from "@/components/Connections/templates/DocumentShare.vue";
import DisplayVideoBox from "@/components/Connections/atoms/DisplayVideoBox.vue";
import ProfileDisplayDialog from "@/components/Connections/templates/ProfileDisplayDialog.vue";
import DocumentSelectDialog from "@/components/Connections/templates/DocumentSelectDialog.vue";
import { ConnectionService, ScreenModeType } from "@/models/room/ConnectionService";
import RightCommandBox from "@/components/Connections/molecles/RightCommandBox.vue";
import AutheticatedFrame from "@/components/Commons/AutheticatedFrame.vue";
import ProfileSelectDialog from "@/components/Connections/templates/ProfileSelectDialog.vue";
import { Profile } from "@/models/profile/Profile";
import { container } from "tsyringe";
import { interval, Observable, Subscription } from "rxjs";
import { WebSocketService } from "@/models/web-socket/WebSocketService";
import { AuthService } from "@/models/auth/AuthService";
import { DocumentShareService } from "@/models/documents/DocumentShareService";
import ConfirmLeaveRoomDialog, { ConfirmLeaveRoomRsultType } from "../organisms/ConfirmLeaveRoomDialog.vue";
import { DrawingService } from "../../../models/drawing/DrawingService";
import { config } from "../../../config";
import { ChatService } from "../../../models/chat/ChatService";
import { delay } from "@/models/utilities/timer";
import qs from "qs";
import SpeechText from "../templates/SpeechText.vue";

/**
 * 接続ページを提供します.
 */
@Component({
    components: {
        DragableBoxView,
        ConfirmLeaveRoomDialog,
        ConnectionSettingDialog,
        DocumentShare,
        FooterCommandBox,
        DisplayVideoBox,
        TimelineView,
        ChatView,
        VideoChat,
        InputDialog,
        ScreenShare,
        DocumentSelectDialog,
        ProfileDisplayDialog,
        RightCommandBox,
        AutheticatedFrame,
        ProfileSelectDialog,
        ConfirmDialog,
        SpeechText
    }
})
export default class VideoChatModeMain extends Vue {
    // #region private fields
    private drawer = true;
    private updateDeviceSubscription?: Subscription;
    private isRightPanelOpend = false;
    private screenModeType = ScreenModeType;
    private isRightPanelResizing = false;
    private rightPanelWidth = 380;
    private unreadChatMessageCount = 0;
    private isVertical = false;
    private notifyMessage = "";
    private notifyColor = "";
    private notifyTimeout = 8000;
    private isShowNotify = false;
    private isLoading = false;
    private isFullscreen = false;
    private isLargeVideo = false;
    private isSkyway = getUrlParameter("provider") === "skyway";
    // #endregion

    // #region private getters
    isStg = location.host.includes("stg") || location.host.includes("localhost");

    /**
     * ルーム情報に関するサービス
     */
    private readonly roomInfoService: RoomInfoService = container.resolve(
        RoomInfoService
    );

    /**
     * ルーム情報に関するサービス
     */
    private readonly authService: AuthService = container.resolve(AuthService);

    /**
     * WebRTCに関するサービス
     */
    private readonly webRtcService: WebRtcService = container.resolve(
        WebRtcService
    );

    /**
     * プロフィール情報に関するサービス
     */
    private readonly profileService: ProfileService = container.resolve(
        ProfileService
    );

    /**
     * 接続情報に関するサービス
     */
    private readonly connectionService: ConnectionService = container.resolve(
        ConnectionService
    );

    readonly websocketService = container.resolve(WebSocketService);

    /**
     * 資料共有サービス
     */
    private readonly documentShareService = container.resolve(DocumentShareService);

    /**
     * マーカーサービス
     */
    private readonly drawingService = container.resolve(DrawingService);

    private readonly chatService = container.resolve(ChatService);

    private readonly videoChatUseCase = container.resolve(VideoChatUseCase);
    private readonly screenShareUseCase = container.resolve(ScreenShareUseCase);

    @Provide()
    public async confirm(message: string, text = "", okText = "OK", cancelText = "キャンセル"): Promise<boolean> {
        const confirmDialog = this.$refs.confirmDialog as any | undefined;
        if (!confirmDialog) return false;
        return (await confirmDialog.showAsync(new ConfirmDialogContent({
            title: message,
            text,
            okText,
            cancelText
        }))) as boolean;
    }

    @Provide()
    public async message(message: string, text = "", okText = "OK", cancelText = "キャンセル"): Promise<void> {
        return await this.showMessage(message, text, okText, cancelText);
    }

    @Provide()
    public notify(message: string, timeout = 8000, color = "primary") {
        this.showNotify(message, timeout, color);
    }

    @Provide()
    public async input(message: string, initialValue = ""): Promise<string> {
        const inputDialog = this.$refs.inputDialog as any | undefined;
        if (!inputDialog) return "";
        const text = await inputDialog.showAsync({ text: message, initialValue }) as string;
        return text;
    }

    private showNotify(message: string, timeout = 8000, color = "primary") {
        this.notifyColor = color;
        this.notifyTimeout = timeout;
        this.notifyMessage = message;
        this.isShowNotify = true;
    }

    private async showMessage(message: string, text = "", okText = "OK", cancelText = "キャンセル"): Promise<void> {
        const confirmDialog = this.$refs.confirmDialog as any | undefined;
        if (!confirmDialog) return;
        await confirmDialog.showAsync(new ConfirmDialogContent({
            title: message,
            text,
            okText,
            cancelText,
            hideCancelButton: true
        }));
    }

    private get videoWidth() {
        if (this.isVertical) {
            return this.isLargeVideo ? 220 : 80;
        }
        return this.isLargeVideo ? 380 : 140;
    }

    private get videoHeight() {
        if (this.isVertical) {
            return this.isLargeVideo ? 380 : 140;
        }
        return this.isLargeVideo ? 220 : 80;
    }

    switchLarge() {
        this.isLargeVideo = !this.isLargeVideo;
    }

    /**
     * 表示する画面のモード
     */
    private get screenMode(): ScreenModeType {
        return this.connectionService.screenMode;
    }
    private set screenMode(mode: ScreenModeType) {
        this.connectionService.setScreenMode(mode);
    }
    // #endregion

    // #region private methods
    /**
     * コンポーネントが作成されたきに実行されます．
     */
    private async mounted(): Promise<void> {
        try {
            this.isLoading = true;

            // 接続終了イベント登録
            this.connectionService.connectionCloseRecieved.add(async () => {
                await this.showMessage("接続が終了しました", "OKを押すとポップアップを閉じます");
                window.close();
            });

            // 上記を3秒に1回実行
            this.updateDeviceSubscription = interval(3000).subscribe(x =>
                this.webRtcService.updateDevices()
            );

            // ルーム情報の取り込み
            const isSuccessFetchRoom = await this.roomInfoService.fetchRoomInfo(Number(this.$route.query.roomId));
            if (!isSuccessFetchRoom || !this.roomInfoService.roomInfo) {
                await this.showMessage("ルームが存在しないか削除されています<br>OK押すとポップアップを閉じます");
                // window.close();
                return;
            }

            this.connectionService.setRoom(this.roomInfoService.roomInfo);

            // 資料があれば読み込む
            if (this.roomInfoService.roomInfo.currentDocId) {
                this.documentShareService.fetchDocument(this.roomInfoService.roomInfo.currentDocId);
            }

            // 現在のモードを適用
            if (this.roomInfoService.roomInfo) {
                this.connectionService.screenMode = this.roomInfoService.roomInfo.currentScreen || ScreenModeType.VideoChat;
            }

            await this.websocketService.connect(this.authService.currentGroupId, this.authService.token);
            await this.websocketService.enter(this.roomInfoService.roomInfo.roomId);

            // SKYWAYへの切り替え
            this.websocketService.onRecieved.add((e: any) => {
                if (e.type === "skyway") {
                    location.assign(location.href + "&provider=skyway");
                }
            });

            // プロフィールの取り込み
            if (this.roomInfoService.roomInfo) {
                this.profileService.fetchProfilesAsync();
                this.profileService.serve(
                    this.roomInfoService.roomInfo.roomId,
                    this.authService.currentGroupId,
                    this.authService.token
                );
            }
            // プロフィールを受信したとき
            // プレビューダイアログに渡す
            this.profileService.recieveProfileHandler.add(profile => {
                this.onPreviewProfile(profile, false);
            });

            // 接続情報の同期
            this.connectionService.nickName = this.$route.query.nickName as string || "";
            this.connectionService.serve(
                this.roomInfoService.roomInfo.roomId,
                this.authService.currentGroupId,
                this.authService.token
            );

            this.webRtcService.setConnectionSetting(
                new ConnectionSetting({
                    roomId: Number(this.$route.query.roomId),
                    audioDeviceId: this.$route.query.audioDeviceId as string,
                    videoDeviceId: this.$route.query.videoDeviceId as string,
                    outputDeviceId: this.$route.query.outputDeviceId as string,
                    mode: this.$route.query.mode as any,
                    quality: Number(this.$route.query.quality),
                    useSfu: this.$route.query.useSfu === "true",
                    roomName: this.$route.query.roomName as string,
                    nickName: this.$route.query.nickName as string,
                    isAudioEnabled: this.$route.query.isAudioEnabled === "true",
                    isVideoEnabled: this.$route.query.isVideoEnabled === "true"
                })
            );

            // 低速の場合再接続させるか確認する
            if (BrowserUtility.hasFlag(BrowserType.Chrome)) {
                const dialog = this.$refs.confirmDialog as any;
                const speed = (navigator as any).connection.downlink as number;
                if (speed <= config.networkLowBps && this.webRtcService.connectionSetting.quality < 2) {
                    const result = await dialog.showAsync(new ConfirmDialogContent({
                        title: "ネットワークが低速です低画質モードで再接続しますか？",
                        okText: "はい",
                        cancelText: "いいえ"
                    }));
                    if (result) {
                        this.webRtcService.connectionSetting.quality = QualityType.Low;
                        location.assign("connection-video-chat?" + qs.stringify(this.webRtcService.connectionSetting));
                    }
                }
                else if (speed < config.networkMiddleBps && this.webRtcService.connectionSetting.quality < 1) {
                    const result = await dialog.showAsync(new ConfirmDialogContent({
                        title: "ネットワークが中速です中画質モードで再接続しますか？",
                        okText: "はい",
                        cancelText: "いいえ"
                    }));
                    if (result) {
                        this.webRtcService.connectionSetting.quality = QualityType.Middle;
                        location.assign("connection-video-chat?" + qs.stringify(this.webRtcService.connectionSetting));
                    }
                }
            }

            // マーカーの送受信サーバーを起動
            this.drawingService.serve(
                this.roomInfoService.roomInfo.roomId,
                this.authService.currentGroupId,
                this.authService.token
            );

            // 使用できるデバイスを列挙しておく
            await this.webRtcService.updateDevices();

            // 資料共有のメッセージ受信を待機
            await this.documentShareService.serve(
                this.roomInfoService.roomInfo.roomId,
                this.authService.currentGroupId,
                this.authService.token
            );

            // Chatのメッセージ受信を待機
            await this.chatService.serve(this.roomInfoService.roomInfo.roomId);
            this.chatService.messageRecieved.add(() => {
                if (!this.isRightPanelOpend) {
                    // this.unreadChatMessageCount++;
                    this.isRightPanelOpend = true;
                    this.showNotify("チャットを受信しました", 2000);
                }
            });

            // ビデオチャットへの接続
            this.videoChatUseCase.connect().then(result => {
                if (!result) {
                    this.showMessage("ビデオチャットへの接続に失敗しました<br>デバイスを確認してください");
                }

                // 5人を超えたとき低画質モードじゃなかったら強制的に低画質モードにしてページをリフレッシュ
                if (this.webRtcService.videoChatConnection) {
                    const streams = this.webRtcService.videoChatConnection.remoteStreams;
                    this.webRtcService.videoChatConnection.remoteStreamsChangedHandler.subscribe(async _ => {
                        if (streams.length >= config.lowQualityThreshouldCount && this.webRtcService.connectionSetting.quality !== QualityType.Low) {
                            this.webRtcService.connectionSetting.quality = QualityType.Low;
                            this.showMessage(`接続人数が${config.lowQualityThreshouldCount}人以上です<br>３秒後に低画質モードで再接続します`);
                            await delay(3000);
                            location.assign(location.pathname + "?" + qs.stringify(this.webRtcService.connectionSetting));
                        }
                    });
                }
                else {
                    console.error("ビデオチャットの接続に失敗しました。");
                }
            });

            // 画面共有への接続
            this.screenShareUseCase.connect().then(result => {
                if (!result) this.showMessage("画面共有サーバへの接続に失敗しました");
            });

            // 右のパネルのリサイズハンドラ
            window.addEventListener("mousemove", e => {
                if (this.isRightPanelResizing) {
                    this.rightPanelWidth -= e.movementX;
                    this.rightPanelWidth = Math.max(4, Math.min(this.rightPanelWidth, 600));
                }
            });
            window.addEventListener(
                "mouseup",
                e => (this.isRightPanelResizing = false)
            );

            this.roomInfoService.sendHistory(this.connectionService.nickName + "さん(プレゼンター)が入室しました", "システム");
            this.isLoading = false;
        }
        catch (ex) {
            console.error("入室に失敗しました", ex);
        }

        this.isVertical = window.innerWidth < window.innerHeight;
        window.addEventListener("resize", e => {
            this.isVertical = window.innerWidth < window.innerHeight;
        });

        this.webRtcService.enter.add(this.onEnter);
        this.webRtcService.left.add(this.onLeft);
    }

    private onLeft(peerId: string) {
        this.showNotify(this.webRtcService.streams[peerId].nickName + "さんが退室しました");
    }

    private onEnter(peerId: string) {
        this.showNotify(this.webRtcService.streams[peerId].nickName + "さんが入室しました");
    }

    /**
     * コンポーネントが破棄される前に実行されます．
     */
    private beforeDestroy() {
        if (this.updateDeviceSubscription) { this.updateDeviceSubscription.unsubscribe(); }
    }

    /**
     * 画面共有を終了します．
     */
    private endScreenShare() {
        this.screenShareUseCase.end();
    }

    /**
     * デバイス情報を更新します
     * @returns 非同期処理
     */
    private async updateDevices(): Promise<void> {
        const devices = await DeviceStreamManager.getDeviceList();
        this.webRtcService.setAudioDevices(devices.audioDeviceList);
        this.webRtcService.setVideoDevices(devices.videoDeviceList);
        this.webRtcService.setOutputDevices(devices.outputDeviceList);
    }

    /**
     * Skywayで再接続
     */
    public connectWithSkyway() {
        this.websocketService.send("broadcastToRoom", { type: "skyway" });
    }

    /**
     * ページを更新します．
     */
    private reload() {
        location.reload();
    }

    /**
     * フルスクリーンへの切り替えがクリックされたとき．
     * @param e クリックイベント情報
     */
    private switchFullScreenClicked(e: any) {
        const target = document.body as any;
        if (this.isFullscreen) {
            if ((document as any).exitFullscreen) (document as any).exitFullscreen(document);
            else if ((document as any).mozCancelFullScreen) (document as any).mozCancelFullScreen(document);
            else if ((document as any).webkitExitFullscreen) (document as any).webkitExitFullscreen(document);
            else if ((document as any).msExitFullscreen) (document as any).msExitFullscreen(document);
            this.isFullscreen = false;
        }
        else {
            if (target.webkitRequestFullScreen) target.webkitRequestFullScreen();
            else if (target.requestFullscreen) target.requestFullscreen();
            else if (target.mozRequestFullScreen) target.mozRequestFullScreen();
            else if (target.msRequestFullscreen) target.msRequestFullscreen();
            this.isFullscreen = true;
        }
    }

    /**
     * 接続を終了します．
     * @description ルームを削除するかどうか選択させます．
     */
    private async hungupClicked(): Promise<void> {
        const confirmLeaveRoomDialog = this.$refs.confirmLeaveRoomDialog as any | undefined;
        if (!confirmLeaveRoomDialog) return;
        const result = await confirmLeaveRoomDialog.showAsync();

        if (result === ConfirmLeaveRoomRsultType.Leave) {
            await this.roomInfoService.sendHistory(this.connectionService.nickName + "さんが一時退室しました", "システム");
            window.close();
            location.assign(config.thanksPage);
        }
        else if (result === ConfirmLeaveRoomRsultType.Destroy) {
            if (this.roomInfoService.roomInfo) {
                await this.roomInfoService.deleteRoom(this.roomInfoService.roomInfo.roomId);
            }

            this.connectionService.closeConnection();
        }
    }

    private beginResizeRightPanel() {
        this.isRightPanelResizing = true;
    }

    /**
     * プロフィール選択ダイアログを開きます．
     */
    private async showProfile(): Promise<void> {
        const displayDialog = this.$refs.profileSelectDialog as
            | any
            | undefined;
        if (!displayDialog) return;
        await displayDialog.show();
    }

    /**
     * プロフィールのプレビューが押された時に実行されます．
     */
    private async onPreviewProfile(profile: Profile, isMe = true): Promise<void> {
        const profileDisplayDialog = this.$refs.profileDisplayDialog as
            | any
            | undefined;
        if (!profileDisplayDialog) return;
        await profileDisplayDialog.showAsync(profile, !isMe);
    }

    /**
     * プロフィールの共有ボタンが押された時に実行されます．
     * @description WebSocketでルーム全員に送信します。
     */
    private async onSendProfileToRoom(profile: Profile) {
        this.onPreviewProfile(profile);
        this.onSend(profile);
    }

    /**
     * プレビュー中にプロフィールの共有ボタンが押された時に実行されます．
     * @description WebSocketでルーム全員に送信します。
     */
    private async onSend(profile: Profile): Promise<void> {
        const dialog = this.$refs.confirmDialog as any | undefined;
        if (!dialog) return;
        await this.profileService.send(profile);
        dialog.showAsync(new ConfirmDialogContent({
            title: "プロフィールを送信しました",
            hideCancelButton: true
        }));
        this.roomInfoService.sendHistory(`${this.connectionService.nickName}さんが<a href="${config.camelUrl}/api/profiles/render/${profile.key}" target="_blank">プロフィール</a>を共有しました`, "システム");
    }

    /**
     * 設定ダイアログを開きます．
     */
    private async openSettingDialog(): Promise<void> {
        const connectionSettingDialog = this.$refs
            .connectionSettingDialog as any;
        if (!connectionSettingDialog) return;
        const setting = await connectionSettingDialog.showAsync(
            new ConnectionSetting(this.webRtcService.connectionSetting)
        );
        if (!setting) return;
        const oldName = this.webRtcService.connectionSetting.nickName;
        this.rewriteQueryStr();
        const isSuccess = await this.videoChatUseCase.update(setting);
        const newName = this.webRtcService.connectionSetting.nickName;
        if (oldName !== newName) this.roomInfoService.sendHistory(oldName + "さんから" + newName + "さんにニックネームを変更しました", "システム");
    }

    @Watch("webRtcService.connectionSetting.outputDeviceId")
    private onSelectedOutputDeviceIdChanged(val: string, old: string) {
        const elements = this.$refs.audioElements as any[];
        if (!elements) return;
        elements.forEach(x => x.setSinkId(this.webRtcService.connectionSetting.outputDeviceId));
    }

    /**
     * URLのクエリ文字列を接続情報で上書きします．
     */
    private rewriteQueryStr() {
        this.videoChatUseCase.syncStream();
        // NOTE: ボタンを押してフラグが書き換わった後に実行するため
        this.$nextTick(() => {
            this.webRtcService.connectionSetting.isVideoEnabled = this.webRtcService.transmitStream.isVideoEnabled;
            this.webRtcService.connectionSetting.isAudioEnabled = this.webRtcService.transmitStream.isAudioEnabled;
            this.$router.replace({ query: this.webRtcService.connectionSetting as any });
        });
    }
    // #endregion
}
